@mixin recrewt-slider-style() {
  .mat-slider-wrapper {
    .mat-slider-thumb-container {
      .mat-slider-thumb-label {
        .mat-slider-thumb-label-text {
          font-size: 20px;
        }
      }
    }
  }
  .mat-slider.albert {
    .mat-slider-wrapper {
      .mat-slider-thumb-container {
        .mat-slider-thumb {
          right: -20px;
          bottom: -20px;
          width: 40px;
          height: 40px;
          background-color: transparent !important;
          background-repeat: no-repeat;
          background-size: 36px 36px;
          background-image: url(../../assets/images/albert.svg) !important;
        }
      }
    }
  }
}
